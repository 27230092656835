<template>
  <div class="w-full h-full flex flex-col justify-around space-y-4">
    <div class="mt-8">
      <!-- title -->
      <div
        class="text-center font-extrabold text-sblue-600 mt-1 text-3xl"
        style="font-family: Metropolis_semiBold"
      >
        Excellent! Our widget is easy to customize
      </div>
      <!-- title -->
      <!-- subtitle  -->
      <div
        class="mt-4 text-center text-sblue-300"
        style="font-family: Metropolis_medium; font-size: 1.375rem"
      >
        We have 3 styles for you to choose from
      </div>
      <!-- subtitle  -->
    </div>
    <!-- theme cards -->
    <div class="flex justify-center space-x-6 mx-9">
      <div
        @click="selectTheme(theme.id)"
        class="
          theme_card
          border-2 border-sblue-200
          rounded-2xl
          flex flex-col
          justify-center
          px-4
          relative
        "
        style="box-shadow: 0px 20px 50px rgba(117, 189, 209, 0.2)"
        v-for="theme in widgetThemes.filter((el) => el.active)"
        :key="theme.id"
      >
        <!-- selected mark -->
        <div
          v-if="this.$store.state.selectedThemeWid == theme.id"
          class="absolute right-4 top-4"
          v-html="getSvg(colors.mainBlue, 36, 36, 'done')"
        ></div>
        <div
          v-else
          class="select_mrk_placeholder absolute right-4 top-4 opacity-50"
          v-html="getSvg(colors.mainBlue, 36, 36, 'done')"
        ></div>
        <!-- selected mark -->
        <!-- theme title -->
        <div
          class="text-center mt-6 mb-4 text-sblue-300"
          style="font-family: Metropolis_medium; font-size: 1.275rem"
        >
          {{ theme.title }}
        </div>
        <!-- theme title -->
        <!-- theme image -->
        <div>
          <img :src="getImgUrl(theme.imgUrl)" />
        </div>
        <!-- theme image -->
      </div>
    </div>
    <!-- theme cards -->
    <!-- buttons -->
    <div
      class="
        flex
        justify-center
        items-center
        space-x-4
        text-sblue-400 text-lg
        cursor-pointer
      "
    >
      <div
        @click="goBack()"
        class="px-8 py-4 flex items-center justify-between"
      >
        <div v-html="getSvg('#2eafd3', 13, 12, 'backArrow')" class="mr-2"></div>
        <div>Back</div>
      </div>
      <router-link to="/widget-customization">
        <div
          v-if="this.$store.state.selectedThemeWid >= 0"
          class="bg-sblue-400 px-8 py-4 text-white rounded-lg"
        >
          Customize
        </div>
      </router-link>
    </div>
    <!-- buttons -->
  </div>
</template>

<script>
import { getSvg } from "../utils/getSvg";
import { getColors } from "../utils/getColors";

export default {
  name: "WidgetThemeList",
  title: "Select your widget's theme",
  components: {},
  data() {
    return {
      colors: {},
      widgetThemes: [
        {
          id: 0,
          title: "Full",
          imgUrl: "full",
          active: 1,
        },
        {
          id: 2,
          title: "Card",
          imgUrl: "card",
          active: 1,
        },
        {
          id: 1,
          title: "Wave",
          imgUrl: "wave",
          active: 1,
        },
      ],
    };
  },
  mounted() {
    this.colors = getColors;
  },
  methods: {
    getSvg: getSvg,
    getImgUrl(url) {
      var images = require.context("../assets/images", false, /\.png$/);
      return images("./" + url + ".png");
    },
    selectTheme(theme) {
      if (this.$store.state.selectedThemeWid == theme) {
        this.$store.commit("resetSelectedThemeWid");
      } else {
        this.$store.commit("setSelectedThemeWid", theme);
      }
    },
    goBack() {
      history.back();
    },
  },
};
</script>

<style scoped>
.theme_card .select_mrk_placeholder {
  visibility: hidden;
}

.theme_card:hover .select_mrk_placeholder {
  visibility: visible;
}
</style>